.participant_overview
	margin-bottom: 20px
	padding: 15px 20px

	.k-card-header
		margin-bottom: 0
		align-items: flex-start

		.k-card-title
			padding: 0

	&__actions
		display: flex
		justify-content: space-between
		align-items: flex-start
		flex: 1

	.participant_overview__content
		overflow-wrap: break-word
